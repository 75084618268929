require("../css/app.scss");
const $ = require("jquery");
require("./countUp.js");
import { CountUp } from "./countUp.js";

let vwidth = $(window).width();
let $userbuttonitem = $(".mainnav__listitem--userbutton");
let $userbutton = $(".userbutton");
let $useraccount = $(".useraccount");
let $modal = $("#modal");
let $modalwrapper = $("#modal-wrapper");
let $modalcontent = $("#modal-content");
let $main = $(".main");
let $mainnav = $(".mainnav");
let $content = $(".content");
let menubutton = $(".menubutton");
let sidewidgetopen = false;
//let perPage = 2;

menubutton.on("click", function () {
  let button = $(this);
  if (button.hasClass("menubutton--show")) {
    openSideNav(button);
  } else {
    closeSideNav(button);
  }
});

$(".accountbutton").on("click", function () {
  let button = $(this);
  if (button.hasClass("accountbutton--show")) {
    button.removeClass("accountbutton--show");
    button
      .find(".accountbutton__logout")
      .addClass("accountbutton__logout--open");
    let width = $(window).width();
    let offset = width - 62;
    $(".accounthead__list").css("width", width + offset);
    $(".accounthead__listitem--user").css({
      "padding-right": 20,
      width: offset
    });
  } else {
    button.addClass("accountbutton--show");
    button
      .find(".accountbutton__logout")
      .removeClass("accountbutton__logout--open");
    $(".accounthead__listitem--user").css({ "padding-right": 0, width: 0 });
    $(".accounthead__list").css("width", "auto");
  }
});

$userbutton.click(function () {
  if ($(this).data("ebu") === 0) {
    if (vwidth < 768) {
      mobileUseraccount(vwidth, $(this));
    } else {
      let lib = $(this).data("lib");
      if (lib === 0) {
        deskShowUseraccount(vwidth);
        $(this).data("lib", 1);
      }
      if (lib === 1) {
        deskHideUseraccount();
        $(this).data("lib", 0);
      }
    }
  }
  if ($(this).data("ebu") === 1) {
    window.location.href = "/user/account";
  }
});

let $showBcForm = $("#showBcForm");
if ($showBcForm.prop("checked") === true) {
  $("." + $showBcForm.val()).show();
}
let $showPcForm = $("#showPcForm");
if ($showPcForm.prop("checked") === true) {
  $("." + $showPcForm.val()).show();
}
$(".form-switcher").click(function () {
  $("." + $(this).data("opponent")).hide();
  $("." + $(this).val()).show();
});

let ebriefTooltipTrigger = $("img.ebrief-tooltip");
$.each(ebriefTooltipTrigger, function () {
  $(this).tipso({
    maxWidth: 300,
    width: true,
    contentElementId: "tooltip-content-id-" + $(this).data("tipso-key"),
    imageDir: "/build/images/",
    imageHover: "info-circle-green.svg",
    imageOut: "info-circle-darkgrey.svg"
  });
});
let ebriefTooltipTriggerLargeInverse = $("img.ebrief-tooltip-large-inverse");
$.each(ebriefTooltipTriggerLargeInverse, function () {
  $(this).tipso({
    maxWidth: 300,
    width: true,
    contentElementId: "tooltip-content-id-" + $(this).data("tipso-key"),
    imageDir: "/build/images/",
    imageHover: "info-circle-darkgrey-large.svg",
    imageOut: "info-circle-green-large.svg"
  });
});

const dezimal = {
  decimalPlaces: 2, // number of decimal places (0)
  separator: ".", // grouping separator (',')
  decimal: ",", // decimal ('.')
  duration: 0.75
};

let versandfaktor = 0;
let pinfaktor = 0;
let zeitfaktor = 0;
let tintenfaktor = 0;
let co2faktor = 0;

let $sparporange,
  $benefitslist,
  $benefitsdot,
  $modelsblock,
  $modelscontrllist,
  $modelscontrl,
  $modelsitemlist,
  $pricesblock,
  $pricescustomcontrl,
  $pricesformatcontrllist,
  $pricesformatcontrl,
  $pricesitemlist,
  $comparisoncontrl,
  $comparisoncontrllist,
  $comparisonitemlist,
  $comparisonitem;

let didScroll;
let lastScrollTop = 0;
let delta = 5;
let mainnavHeight = $mainnav.outerHeight();
let mainStartOffsetTop = 0;
if ($main.length) {
  mainStartOffsetTop = $main.offset().top;
}
$(window).scroll(function (event) {
  didScroll = true;
});
setInterval(function () {
  if (didScroll) {
    handleSticky();
    didScroll = false;
  }
}, 250);

$(window).resize(function () {
  handleModal();
});
handleModal();
handleFaq();
handleTracking();
genCommissionTablesPagi();
handleContactPreFill();

$("#modal-close").click(function () {
  $modal.addClass("closed");
});

$(".js-smooth-anchor").on("click", function (event) {
  if (this.hash !== "") {
    let hash = this.hash;
    let offset = $(hash).offset();
    if (typeof offset !== "undefined") {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: offset.top
        },
        800,
        function () {
          window.location.hash = hash;
        }
      );
    }
  }
});

$(".js-sidnav-hashlink").on("click", function (event) {
  clickSidnavHashlink(this, event);
});

$main.on(
  "webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
  function (event) {
    if (event.originalEvent.propertyName === "margin-left") {
      if (!sidewidgetopen) {
        $content.css("width", "auto");
        $("body").removeAttr("style"); // alternativ $('body').css('overflow', 'auto');
      }
    }
  }
);

if (typeof sparpo !== "undefined") {
  let sparpoJson = JSON.parse(sparpo);
  versandfaktor = parseFloat(sparpoJson.versandfaktor);
  pinfaktor = parseFloat(sparpoJson.pinfaktor);
  zeitfaktor = parseFloat(sparpoJson.zeitfaktor);
  tintenfaktor = parseFloat(sparpoJson.tintenfaktor);
  co2faktor = parseFloat(sparpoJson.co2faktor);

  $sparporange = $(".sparpo__range");
  $benefitslist = $(".benefits__list");
  $benefitsdot = $(".benefits__dot");
  $modelscontrllist = $(".models__contrllist");
  $modelscontrl = $(".models__contrl");
  $modelsblock = $(".models");
  $modelsitemlist = $(".models__itemlist");
  $pricesblock = $(".prices");
  $pricescustomcontrl = $(".prices__customcontrol_item");
  $pricesformatcontrllist = $(".prices__formatcontrol_list");
  $pricesformatcontrl = $(".prices__formatcontrol_item");
  $pricesitemlist = $(".prices__itemlist");

  $sparporange
    .rangeslider({
      polyfill: false,
      onInit: function () {
        updateBPM(this.value);
      }
    })
    .on("input", function () {
      updateBPM(this.value);
    });

  $(window).resize(function () {
    showBenefits($benefitslist.data("current-benefit"));
    showModel($modelsitemlist.data("current-model"));
    showPrices(
      $pricesitemlist.data("current-price"),
      $pricesitemlist.data("current-customs")
    );
  });

  showBenefits($benefitslist.data("current-benefit"));
  $benefitsdot.click(function () {
    showBenefits($(this).data("benefit"));
  });
  $(".benefits")
    .touch({
      trackDocument: true,
      trackDocumentNormalize: true,
      preventDefault: {
        swipeLeft: true,
        swipeRight: true
      }
    })
    .on("swipeLeft", function (e, o) {
      let next = parseInt($benefitslist.data("current-benefit")) + 1;
      showBenefits(next);
    })
    .on("swipeRight", function (e, o) {
      let next = parseInt($benefitslist.data("current-benefit")) - 1;
      showBenefits(next);
    });
  showModel($modelsitemlist.data("current-model"));
  $modelscontrl.click(function () {
    showModel($(this).data("model"));
  });
  showPrices(
    $pricesitemlist.data("current-price"),
    $pricesitemlist.data("current-customs")
  );
  $pricescustomcontrl.click(function () {
    $pricescustomcontrl.removeClass("prices__customcontrol_item--active");
    $(this).addClass("prices__customcontrol_item--active");
    showPrices($pricesitemlist.data("current-price"), $(this).data("custom"));
  });
  $pricesformatcontrl.click(function () {
    showPrices($(this).data("format"), $pricesitemlist.data("current-customs"));
  });
  if ($(window).width() < 870) {
    $pricesitemlist
      .touch({
        trackDocument: true,
        trackDocumentNormalize: true,
        preventDefault: {
          swipeLeft: true,
          swipeRight: true
        }
      })
      .on("swipeLeft", function (e, o) {
        let next = parseInt($pricesitemlist.data("current-price")) + 1;
        showPrices(next, $pricesitemlist.data("current-customs"));
      })
      .on("swipeRight", function (e, o) {
        let next = parseInt($pricesitemlist.data("current-price")) - 1;
        showPrices(next, $pricesitemlist.data("current-customs"));
      });
  }
  $modelsitemlist
    .touch({
      trackDocument: true,
      trackDocumentNormalize: true,
      preventDefault: {
        swipeLeft: true,
        swipeRight: true
      }
    })
    .on("swipeLeft", function (e, o) {
      let next = parseInt($modelsitemlist.data("current-model")) + 1;
      showModel(next);
    })
    .on("swipeRight", function (e, o) {
      let next = parseInt($modelsitemlist.data("current-model")) - 1;
      showModel(next);
    });
}

if (typeof lead !== "undefined") {
  $comparisoncontrllist = $(".comparison__contrllist");
  $comparisoncontrl = $(".comparison__contrl");
  $comparisonitemlist = $(".comparison__itemlist");
  $comparisonitem = $(".comparison__item");
  showComparison($comparisonitemlist.data("current-comparison"));
  $(window).resize(function () {
    showComparison($comparisonitemlist.data("current-comparison"));
  });
  $comparisoncontrl.click(function () {
    let cc = $(this).data("comparison");
    $comparisonitemlist.data("current-comparison", cc);
    showComparison(cc);
  });
  $comparisonitemlist
    .touch({
      trackDocument: true,
      trackDocumentNormalize: true,
      preventDefault: {
        swipeLeft: true,
        swipeRight: true
      }
    })
    .on("swipeLeft", function (e, o) {
      showComparison("api");
    })
    .on("swipeRight", function (e, o) {
      showComparison("ftp");
    });
}

function mobileUseraccount(width, button) {
  if (button.hasClass("useraccount--show")) {
    closeSideUser(button);
  } else {
    openSideUser(button, width);
  }
}

function deskShowUseraccount(width) {
  let ubw = $userbuttonitem.width();
  let ubh = $userbutton.height();
  let ubl = $userbutton.offset().left;
  let ubc = ubw / 2 + ubl;
  let uaw = $useraccount.width();
  let ual = ubc - uaw / 2;
  $useraccount.css("top", $userbutton.offset().top + ubh); // + 8
  let uar = ual + uaw;
  if (uar > width) {
    let diff = uar - width;
    ual = ual - diff - 10;
    $(".useraccount__arrow").css("left", diff + 10);
  }
  $useraccount.css({ left: ual, height: 468 });
}

function deskHideUseraccount() {
  $useraccount.css({ height: 0 });
}

function updateBPM(bpm) {
  bpm = parseInt(bpm);
  if (bpm > 1) {
    bpm = bpm - 1;
  }
  $(".sparpo__bpmvalue").text(numberFormatDE(bpm));
  let sparpodpag = bpm * versandfaktor;
  $(".sparpo__versanddpag").text(formatMoney(sparpodpag) + " €");
  let sparpopin = parseFloat(bpm * pinfaktor);
  $(".sparpo__versandpin").text(formatMoney(sparpopin));
  const kosten = new CountUp(
    "sparpo__kosten",
    parseFloat(sparpodpag - sparpopin),
    dezimal
  );
  if (!kosten.error) {
    kosten.start();
  } else {
    console.error(kosten.error);
  }
  const arbeitszeit = new CountUp(
    "sparpo__arbeitszeit",
    parseFloat((bpm * zeitfaktor) / 60),
    dezimal
  );
  if (!arbeitszeit.error) {
    arbeitszeit.start();
  } else {
    console.error(arbeitszeit.error);
  }
  const druckertinte = new CountUp(
    "sparpo__druckertinte",
    parseFloat(bpm * tintenfaktor),
    dezimal
  );
  if (!druckertinte.error) {
    druckertinte.start();
  } else {
    console.error(druckertinte.error);
  }
  const co2 = new CountUp("sparpo__co2", parseFloat(bpm * co2faktor), dezimal);
  if (!co2.error) {
    co2.start();
  } else {
    console.error(co2.error);
  }
}

function showBenefits(benefitsIndex) {
  let i;
  let benefits = $(".benefits__item");
  if ($(window).width() < 960) {
    if (benefitsIndex > benefits.length) {
      benefitsIndex = 1;
    }
    if (benefitsIndex < 1) {
      benefitsIndex = benefits.length;
    }
    for (i = 0; i < benefits.length; i++) {
      $(benefits[i]).css("display", "none");
    }
    for (i = 0; i < $benefitsdot.length; i++) {
      $($benefitsdot[i]).removeClass("benefits__dot--active");
    }
    $(benefits[benefitsIndex - 1]).css("display", "block");
    $(".benefits__dots").css("display", "block");
    $($benefitsdot[benefitsIndex - 1]).addClass("benefits__dot--active");
    $benefitslist.data("current-benefit", benefitsIndex);
  } else {
    for (i = 0; i < benefits.length; i++) {
      $(benefits[i]).css("display", "inline-block");
    }
    $(".benefits__dots").css("display", "none");
  }
}

function showModel(modelIndex) {
  let i;
  let models = $(".models__item");

  let centerSmall = 694; // 562
  let diffSmall = 85;
  let centerMedium = centerSmall + 5; // 562
  let diffMedium = 90;
  let centerHigh = 840; // 672
  let diffHigh = diffSmall + 20;

  let structure = {
    0: {
      leftheight: centerSmall - diffSmall,
      itemheight: centerSmall,
      rightheight: centerSmall + diffSmall,
      order: [0, 1, 2]
    }, // {leftheight: 477, itemheight: 562, rightheight: 647, order: [0, 1, 2]}
    1: {
      leftheight: centerSmall + diffSmall,
      itemheight: centerSmall - diffSmall,
      rightheight: centerSmall,
      order: [2, 0, 1]
    }, // {leftheight: 647, itemheight: 477, rightheight: 562, order: [2, 0, 1]}
    2: {
      leftheight: centerSmall - diffSmall,
      itemheight: centerSmall,
      rightheight: centerSmall + diffSmall,
      order: [0, 1, 2]
    }, // {leftheight: 477, itemheight: 562, rightheight: 647, order: [0, 1, 2]}
    3: {
      leftheight: centerSmall,
      itemheight: centerSmall + diffSmall,
      rightheight: centerSmall - diffSmall,
      order: [1, 2, 0]
    }, // {leftheight: 562, itemheight: 647, rightheight: 477, order: [1, 2, 0]}
    4: {
      leftheight: centerMedium - diffMedium,
      itemheight: centerMedium,
      rightheight: centerMedium + diffMedium,
      order: [0, 1, 2]
    }, // {leftheight: 477, itemheight: 567, rightheight: 657, order: [0, 1, 2]}
    5: {
      leftheight: centerHigh - diffHigh,
      itemheight: centerHigh,
      rightheight: centerHigh + diffHigh,
      order: [0, 1, 2]
    } // {leftheight: 567, itemheight: 672, rightheight: 777, order: [0, 1, 2]}
  };
  let currentstructure;
  if (modelIndex > models.length) {
    modelIndex = 1;
  }
  if (modelIndex < 1) {
    modelIndex = models.length;
  }
  if ($(window).width() > 870) {
    modelIndex = 0;
  }
  if ($(window).width() > 959) {
    modelIndex = 4;
  }
  if ($(window).width() > 1199) {
    modelIndex = 5;
  }
  for (i = 0; i < $modelscontrl.length; i++) {
    $($modelscontrl[i]).removeClass("models__contrl--active");
  }
  $($modelscontrl[modelIndex - 1]).addClass("models__contrl--active");
  models.removeClass("models__item--center");
  let elements = [];
  elements.push($(".models__item--kleine"));
  elements.push($(".models__item--mittlere"));
  elements.push($(".models__item--grosse"));
  models.remove();
  currentstructure = structure[modelIndex];
  currentstructure.order.forEach(function (ei) {
    $modelsitemlist.append(elements[ei]);
  });

  console.log(modelIndex);

  //Shift.
  if ($(window).width() < 870 && modelIndex == 1) {
    currentstructure.itemheight = currentstructure.itemheight + 10;
    currentstructure.leftheight = currentstructure.leftheight + 31;
    currentstructure.rightheight = currentstructure.rightheight + 31;
  }

  if ($(window).width() < 870 && modelIndex == 2) {
    currentstructure.itemheight = currentstructure.itemheight + 20;
    currentstructure.leftheight = currentstructure.leftheight - 5;
    currentstructure.rightheight = currentstructure.rightheight + 16;
  }

  if ($(window).width() < 870 && modelIndex == 3) {
    currentstructure.itemheight = currentstructure.itemheight + 25;
    currentstructure.rightheight = currentstructure.rightheight + 6;
    currentstructure.leftheight = currentstructure.leftheight + 32;
  }

  $(".models__itemlist li.models__item:nth-child(2)")
    .addClass("models__item--center")
    .find(".models__item_content")
    .css("height", currentstructure.itemheight);
  $(".models__itemlist li.models__item:nth-child(1)")
    .find(".models__item_content")
    .css("height", currentstructure.leftheight);
  $(".models__itemlist li.models__item:nth-child(3)")
    .find(".models__item_content")
    .css("height", currentstructure.rightheight);
  let left = 0;
  if ($modelsitemlist.width() > $modelsblock.width()) {
    left = -(($modelsitemlist.width() - $modelsblock.width()) / 2);
  }
  $modelsitemlist.css("left", left);
  $modelsitemlist.data("current-model", modelIndex);
  if ($(window).width() > 870) {
    $modelscontrllist.css("display", "none");
  } else {
    $modelscontrllist.css("display", "block");
  }
}

function showPrices(currentprice, currentcustoms) {
  let i;
  let opponent = {
    bussines: "privat",
    privat: "bussines"
  };
  let structure = {
    0: [0, 1, 2],
    1: [2, 0, 1],
    2: [0, 1, 2],
    3: [1, 2, 0]
  };
  let currentstructure;
  let prices = $(".prices__item--" + currentcustoms);
  prices.css("display", "table-cell");
  $(".prices__item--" + opponent[currentcustoms]).css("display", "none");
  if (currentprice > prices.length) {
    currentprice = 1;
  }
  if (currentprice < 1) {
    currentprice = prices.length;
  }
  if ($(window).width() > 869) {
    currentprice = 0;
  }
  for (i = 0; i < $pricesformatcontrl.length; i++) {
    $($pricesformatcontrl[i]).removeClass("prices__formatcontrol_item--active");
  }
  $($pricesformatcontrl[currentprice - 1]).addClass(
    "prices__formatcontrol_item--active"
  );
  prices.removeClass("prices__item--center");
  let elements = [];
  elements.push($(".prices__item--" + currentcustoms + "-standard"));
  elements.push($(".prices__item--" + currentcustoms + "-kompakt"));
  elements.push($(".prices__item--" + currentcustoms + "-gross"));
  prices.remove();
  currentstructure = structure[currentprice];
  currentstructure.forEach(function (ei) {
    $pricesitemlist.append(elements[ei]);
  });
  $(".prices__itemlist li.prices__item:nth-child(5)").addClass(
    "prices__item--center"
  );
  let left = 0;
  if ($pricesitemlist.width() > $pricesblock.width()) {
    left = -(($pricesitemlist.width() - $pricesblock.width()) / 2);
  }
  $pricesitemlist.css("left", left);
  $pricesitemlist.data("current-price", currentprice);
  $pricesitemlist.data("current-customs", currentcustoms);
  if ($(window).width() > 869) {
    $pricesformatcontrllist.css("display", "none");
  } else {
    $pricesformatcontrllist.css("display", "block");
  }

  $.each(ebriefTooltipTrigger, function () {
    $(this).tipso({
      maxWidth: 300,
      width: true,
      contentElementId: "tooltip-content-id-" + $(this).data("tipso-key"),
      imageDir: "/build/images/",
      imageHover: "info-circle-green.svg",
      imageOut: "info-circle-darkgrey.svg"
    });
  });
}

function showComparison(currentComparison) {
  $comparisonitemlist.css("left", 0);
  let i;
  if ($(window).width() < 640) {
    let comparisonitems = $(".comparison__item");
    let windowwidth = $(window).width();
    let itemwidth = comparisonitems.outerWidth();
    let positionleft = $comparisonitemlist.position().left;
    let offset = 0;
    if (currentComparison === "api") {
      offset = -itemwidth;
    }
    offset = offset + ((windowwidth - itemwidth) / 2 - positionleft);
    $comparisonitemlist.css("left", offset);
    for (i = 0; i < $comparisoncontrl.length; i++) {
      $($comparisoncontrl[i]).removeClass("comparison__contrl--active");
      if ($($comparisoncontrl[i]).data("comparison") === currentComparison) {
        $($comparisoncontrl[i]).addClass("comparison__contrl--active");
      }
    }
    for (i = 0; i < $comparisonitem.length; i++) {
      $($comparisonitem[i]).removeClass("comparison__item--active");
      if (
        $($comparisonitem[i]).hasClass("comparison__item--" + currentComparison)
      ) {
        $($comparisonitem[i]).addClass("comparison__item--active");
      }
    }
  } else {
    for (i = 0; i < $comparisonitem.length; i++) {
      $($comparisonitem[i]).removeClass("comparison__item--active");
    }
  }
}

function handleSticky() {
  let st = $(window).scrollTop();
  if (Math.abs(lastScrollTop - st) <= delta) {
    return;
  }

  if (
    st > mainStartOffsetTop &&
    st > lastScrollTop &&
    st > mainnavHeight + mainStartOffsetTop
  ) {
    navHide();
  } else if (
    st > mainStartOffsetTop &&
    st > mainnavHeight + mainStartOffsetTop
  ) {
    if (st + $(window).height() < $(document).height()) {
      navShow();
    }
  } else {
    navDocking();
  }

  lastScrollTop = st;
}

function navHide() {
  $mainnav
    .css({
      position: "fixed",
      top: -mainnavHeight
    })
    .parent()
    .css({
      "padding-top": mainnavHeight
    });
}

function navShow() {
  $mainnav.css({
    position: "fixed",
    top: 0,
    "box-shadow": "0 1px 15px rgba(47, 79, 79, 0.5)"
  });
}

function navDocking() {
  $mainnav.removeAttr("style").parent().css({
    "padding-top": 0
  });
}

function handleModal() {
  if ($modal.length) {
    showModal();
  }
}

function showModal() {
  let windowwidth = $(window).width();
  if (
    $modalcontent.data("mobil-width") !== undefined &&
    $modalcontent.data("desk-width") !== undefined
  ) {
    let modalcontenwidth = 0;
    if (windowwidth < 790) {
      modalcontenwidth = $modalcontent.data("mobil-width");
    } else {
      modalcontenwidth = $modalcontent.data("desk-width");
    }
    let modalwrapperwidth = modalcontenwidth + 40;
    $modalwrapper.css("width", modalwrapperwidth);
    $modalcontent.css("width", modalcontenwidth);
    if (windowwidth > modalwrapperwidth) {
      $modalwrapper.css("margin-left", -(modalwrapperwidth / 2));
    }
  }
  if (
    $modalcontent.data("mobil-height") !== undefined &&
    $modalcontent.data("desk-height") !== undefined
  ) {
    let modalcontenheight = 0;
    if (windowwidth < 790) {
      modalcontenheight = $modalcontent.data("mobil-height");
    } else {
      modalcontenheight = $modalcontent.data("desk-height");
    }
    let modalwrapperheight = modalcontenheight + 40;
    $modalwrapper.css("height", modalwrapperheight);
    $modalcontent.css("height", modalcontenheight);
    if ($(window).height() > modalwrapperheight) {
      $modalwrapper.css("margin-top", -(modalwrapperheight / 2));
    }
  }
}

function numberFormatDE(num) {
  return num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

function formatMoney(number) {
  let decPlaces = 2;
  let decSep = ",";
  let thouSep = ".";
  var sign = number < 0 ? "-" : "";
  var i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
}

function handleContactPreFill() {
  $("#contact_general").each(function () {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      $(this)
        .find("#contact_issue option")
        .each(function () {
          var key = $(this).val();
          if (key.indexOf(hash) >= 0) {
            $(this).attr("selected", "selected");
          }
        });
    }
  });
}

function handleFaq() {
  let faq_listitem_question = $(".faq__listitem_question");
  faq_listitem_question.click(function () {
    let parent = $(this).parent();
    let faq_id = $(this).data("faq-id");
    let open = parent.hasClass("faq__listitem faq__listitem--visibel");
    $(".faq__listitem_question-icon").removeClass(
      "faq__listitem_question-icon--open"
    );
    $(".faq__listitem").removeClass("faq__listitem--visibel");
    $(".faq__listitem_answer").removeClass("faq__listitem_answer--visibel");
    $(".faq__listitem_question-header").removeClass(
      "faq__listitem_question-header--visibel"
    );
    if (!open) {
      parent
        .find(".faq__listitem_question-icon")
        .addClass("faq__listitem_question-icon--open");
      $("#faq-item-" + faq_id).addClass("faq__listitem--visibel");
      $("#faq-answer-" + faq_id).addClass("faq__listitem_answer--visibel");
      $("#faq-question-" + faq_id).addClass(
        "faq__listitem_question-header--visibel"
      );
    }
  });
}

function handleTracking() {
  let jobelement__trackingtoggle = $(".jobelement__trackingtoggle");
  jobelement__trackingtoggle.click(function () {
    let commissionid = $(this).data("commissionid");
    this.classList.toggle("jobelement__trackingtoggle--tablevisibel");
    let target = document.getElementById(
      "jobelement-trackingcontent-" + commissionid
    );
    target.classList.toggle("jobelement__trackingcontent--visibel");
    let table = document.getElementById(
      "jobelement-trackingtable-" + commissionid
    );
    if (table.rows.length > table.dataset.pagecount) {
      let pagi = document.getElementById(
        "jobelement-trackingpagination-" + commissionid
      );
      pagi.classList.toggle("jobelement__trackingpagination--visibel");
    }
  });
}

function openSideNav(button) {
  button.removeClass("menubutton--show");
  $(".menubutton__rounded--first").addClass("menubutton__rounded--rotate45");
  $(".menubutton__rounded--second").addClass("menubutton__rounded--rotate135");
  let width = $(window).width();
  let offset = width - 62;
  $(".sidenav").css("width", offset);
  $(".sidenav__listitem--headeritem").css("width", offset);
  $(".sidenav__listwrapper").css({ display: "table", width: offset });
  $main.css({ "margin-left": offset, width: width });
  $mainnav.css({ position: "fixed", "z-index": 9999999, "box-shadow": "none" });
  if ($(window).scrollTop() < mainnavHeight) {
    $content.css({ "padding-top": "50px" });
  }
  $content.css({ width: width });
  $("body").css("overflow", "hidden");
  sidewidgetopen = true;
}

function closeSideNav(button, shownav) {
  if (typeof shownav === "undefined") {
    shownav = true;
  }
  button.addClass("menubutton--show");
  $(".menubutton__rounded--first").removeClass("menubutton__rounded--rotate45");
  $(".menubutton__rounded--second").removeClass(
    "menubutton__rounded--rotate135"
  );
  $(".sidenav__listitem--headeritem").css("width", 0);
  $(".sidenav__listwrapper").css({ display: "none", width: 0 });
  $(".sidenav").css("width", 0);
  $mainnav.removeAttr("style");
  if ($(window).scrollTop() < mainnavHeight) {
    $content.css("padding-top", 0);
  } else if (shownav) {
    navShow();
  }
  $main.css({ "margin-left": 0, width: "auto" });
  sidewidgetopen = false;
}

function openSideUser(button, width) {
  button.addClass("useraccount--show");
  let offset = width - 62;
  $useraccount.css("width", offset);
  $(".useraccount__arrow").css("width", offset);
  $(".useraccount__logo").css("width", offset);
  $main.css({ "margin-left": -offset, width: width });
  $mainnav.css({ position: "fixed", "z-index": 9999999, "box-shadow": "none" });
  if ($(window).scrollTop() < mainnavHeight) {
    console.log("set content top padding by user");
    $content.css({ "padding-top": "50px" });
  }
  $content.css({ width: width });
  $("body").css("overflow", "hidden");
  sidewidgetopen = true;
}

function closeSideUser(button) {
  button.removeClass("useraccount--show");
  $(".useraccount__arrow").css("width", 0);
  $useraccount.css("width", 0);
  $mainnav.removeAttr("style"); // alternativ $mainnav.css({'position': 'relative', 'z-index': 1});
  if ($(window).scrollTop() < mainnavHeight) {
    $content.css("padding-top", 0);
  } else {
    navShow();
  }
  $main.css({ "margin-left": 0, width: "auto" });
  $(".useraccount__newuser").css("bottom", 0);
  sidewidgetopen = false;
}

function clickSidnavHashlink(link, event) {
  closeSideNav(menubutton, false);
  if (link.hash !== "") {
    let hash = link.hash;
    let offset = $(hash).offset();
    if (typeof offset !== "undefined") {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: offset.top
        },
        800,
        function () {
          window.location.hash = hash;
        }
      );
    }
  }
}

function genCommissionTablesPagi() {
  let tables = document.querySelectorAll(".jobelement__trackingtable");
  for (let i = 0; i < tables.length; i++) {
    createFooters(tables[i]);
    createTableMeta(tables[i]);
    loadTable(tables[i]);
  }
}

function loadTable(table) {
  let startIndex = 0;

  if (table.querySelector("th")) {
    startIndex = 1;
  }

  let start =
    parseInt(table.dataset.currentpage) * table.dataset.pagecount + startIndex;
  let end = start + parseInt(table.dataset.pagecount);
  let rows = table.rows;

  for (let x = startIndex; x < rows.length; x++) {
    if (x < start || x >= end) {
      rows[x].classList.add("jobelement__trackingtablecell--hidden");
    } else {
      rows[x].classList.remove("jobelement__trackingtablecell--hidden");
    }
  }

  let copyShipmentNumberButtons = document.querySelectorAll(
    ".jobelement__trackingcopy"
  );
  for (let i = 0; i < copyShipmentNumberButtons.length; i++) {
    let copyShipmentNumber = copyShipmentNumberButtons[i];
    copyShipmentNumber.addEventListener("click", function () {
      copyTextToClipboard(this.dataset.shipmentnumber);
    });
  }
}

function createTableMeta(table) {
  table.dataset.currentpage = "0";
}

function createFooters(table) {
  let hasHeader = false;
  if (table.querySelector("th")) {
    hasHeader = true;
  }

  let perPage = table.dataset.pagecount;
  let rows = table.rows.length;

  if (hasHeader) {
    rows = rows - 1;
  }

  let numPages = rows / perPage;
  let pager = document.createElement("ul");

  if (numPages % 1 > 0) {
    numPages = Math.floor(numPages) + 1;
  }

  pager.className = "jobelement__trackingpaginationbody";
  let previous = document.createElement("li");
  previous.className = "jobelement__trackingpaginationitem";
  previous.classList.add("jobelement__trackingpaginationitem--previous");
  previous.addEventListener("click", function () {
    let lastpage = numPages - 1;
    let newpage = parseInt(table.dataset.currentpage) - 1;
    if (newpage < 0) {
      newpage = lastpage;
    }
    table.dataset.currentpage = newpage;
    let all = document.getElementById(
      "jobelement-trackingpaginationitem-all-" + table.dataset.commissionid
    );
    let from = newpage * parseInt(perPage) + 1;
    let till = (newpage + 1) * parseInt(perPage);
    if (till > rows) {
      till = rows;
    }
    if (from === till) {
      all.innerHTML = from + " von " + rows;
    } else {
      all.innerHTML = from + " - " + till + " von " + rows;
    }
    loadTable(table);
  });
  pager.appendChild(previous);
  let all = document.createElement("li");
  all.setAttribute(
    "id",
    "jobelement-trackingpaginationitem-all-" + table.dataset.commissionid
  );
  all.className = "jobelement__trackingpaginationitem";
  all.classList.add("jobelement__trackingpaginationitem--all");
  let from = parseInt(table.dataset.currentpage) * parseInt(perPage) + 1;
  let till = (parseInt(table.dataset.currentpage) + 1) * parseInt(perPage);
  all.innerHTML = from + " - " + till + " von " + rows;
  pager.appendChild(all);
  let next = document.createElement("li");
  next.className = "jobelement__trackingpaginationitem";
  next.classList.add("jobelement__trackingpaginationitem--next");
  next.addEventListener("click", function () {
    let lastpage = numPages - 1;
    let newpage = parseInt(table.dataset.currentpage) + 1;
    if (newpage > lastpage) {
      newpage = 0;
    }
    table.dataset.currentpage = newpage;
    let all = document.getElementById(
      "jobelement-trackingpaginationitem-all-" + table.dataset.commissionid
    );
    let from = newpage * parseInt(perPage) + 1;
    let till = (newpage + 1) * parseInt(perPage);
    if (till > rows) {
      till = rows;
    }
    if (from === till) {
      all.innerHTML = from + " von " + rows;
    } else {
      all.innerHTML = from + " - " + till + " von " + rows;
    }
    loadTable(table);
  });
  pager.appendChild(next);

  let pagi = document.getElementById(
    "jobelement-trackingpagination-" + table.dataset.commissionid
  );
  pagi.insertAdjacentElement("afterbegin", pager);
}

function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand("copy");
    let msg = successful ? "successful" : "unsuccessful";
    if (msg === "unsuccessful") {
      console.error("unable to copy shipment number");
    }
  } catch (err) {
    console.error("unable to copy shipment number", err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

$(".js-references-list-ebrief").slick({
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600, //820
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
});

$(".js-testimonials-list-ebrief").slick({
  autoplay: true,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1
});
